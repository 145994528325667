@use "../../../../style/theme_dark" as dark
@use "../../../../style/theme_light" as *
@use "../../../../style/_styles" as *
@use "../../../../style/_primitives" as *

.cp_component_highlights-autocomplete

  div.cp_component_input
    width: auto

div.cp_highlights-metrics-dropdown
  width: fit-content !important

  p.metrics-title
    @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
    margin-left: $space_2x
    margin-top: $space_2x
    text-transform: uppercase
  
  p.metrics-search-no-result
    @include textStyles($color_input-error, $font-size-normal, $font-weight-normal)
    padding: $space_2x $space_5x

  .group-highlights
    &-parent-list-item
      .list-item-expand-contract
        fill: $color_text-inactive !important
      &-text
        @include textStyles($color_text-dropdown, $font-size-sm, $font-weight-normal)
    &-child-list-item
      margin-left: $space_5x
      &-cb
        scale: 0.7
      &-text
        @include textStyles($color_text-dropdown, $font-size-sm, $font-weight-normal)

  .filtered-metric
    color: $color_text-dropdown

  .audience-metric-button
    .audience-cb
      color: $color_text-inactive
      height: 34px !important
      margin-left: $space_3x
      width: 34px !important

      svg
        height: px-to-rem(16px)
        width: px-to-rem(16px)
    
    .audience-metric
      color: $color_text-dropdown
      margin-left: 1.4rem

  .list-item-text
    color: $color_text-dropdown

.dark
  div.cp_highlights-metrics-dropdown
    p.metrics-title
      @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bold)
    p.metrics-search-no-result
      @include textStyles(dark.$color_input-error, $font-size-normal, $font-weight-normal)

    .group-highlights
      &-parent-list-item
        .list-item-expand-contract
          fill: dark.$color_text-inactive !important
        &-text
          @include textStyles(dark.$color_text-dropdown, $font-size-sm, $font-weight-normal)
      &-child-list-item
        &-text
          @include textStyles(dark.$color_text-dropdown, $font-size-sm, $font-weight-normal)

    .filtered-metric
      color: dark.$color_text-dropdown

    .audience-metric-button
      .audience-cb
        color: dark.$color_text-inactive
      .audience-metric
        color: dark.$color_text-dropdown
