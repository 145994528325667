@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.communications-email-list-view
  &#cp_comm-messages-empty

    .cp_comm-messages-empty
      display: flex
      flex-direction: column
      row-gap: $space_3x
      min-height: 100%
      padding: $space_1x
      position: relative
      overflow: hidden

      p
        @include textStyles($color_text-inactive, px-to-rem(18px), $font-weight-normal)
        position: absolute
        top: px-to-rem(180px)
        width: 100%
        text-align: center
        z-index: 1
        // font-weight: 700

      div.cp_ghost-box
        height: 106px
        width: 100%
        background-color: $color_pill-ghost-box

.dark
  .communications-email-list-view
    &#cp_comm-messages-empty
      .cp_comm-messages-empty
        p
          @include textStyles(dark.$color_text-inactive, px-to-rem(18px), $font-weight-normal)
        div.cp_ghost-box
          background-color: dark.$color_pill-ghost-box