@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_component_public-list-info-card
    &.MuiContainer-root
        background-color: $color_fill-modal
        border: 1px solid $color_border-section
        padding: $space_4x
        width: px-to-rem(380px)
    &:hover
        cursor: pointer
    &-main
        &-name
            font-size: px-to-rem(22px)
            font-family: $font-family-primary
        &-divider.MuiDivider-root
            border-color: $color_fill-card-blue
            width: px-to-rem(57px)
            margin-top: $space_1x
            margin-bottom: $space_3x
        &-description
            -webkit-box-orient: vertical
            -webkit-line-clamp: 2
            color: $color_text-secondary
            display: -webkit-box
            height: px-to-rem(40px)
            margin-bottom: $space_3x
            overflow: hidden
            text-overflow: ellipsis
            word-wrap: break-word
        &-expand-button, &-less-button
            margin-bottom: $space_3x
            text-align: center
        &-demographic
            color: $color_text-primary
            font-weight: $font-weight-bolder
            font-size: $font-size-sm
            text-transform: uppercase
            margin-bottom: $space_1x
        &-pill
            height: px-to-rem(24px)
            margin-bottom: $space_3x
    &-divider.MuiDivider-root
        align-self: center
        border-color: $color_divider-section
        width: 100%
        margin: 0 auto $space_3x auto
    &-stats
        display: flex
        justify-content: space-between
        &-info
            display: flex
            flex-direction: column
            justify-content: space-between
            &-network
                height: px-to-rem(28px)
                padding: px-to-rem(8px)
                width: px-to-rem(28px)
            h4
                color: $color_icon-logo
                font-size: px-to-rem(28px)
                text-transform: uppercase
            &-title
                color: $color_text-secondary
                font-weight: $font-weight-bolder
                font-size: $font-size-sm
                text-transform: uppercase

.dark
    .cp_component_public-list-info-card
        &.MuiContainer-root
            background-color: dark.$color_fill-modal
            border-color: dark.$color_border-section
        &-main
            &-divider.MuiDivider-root
                border-color: dark.$color_fill-logo
            &-description
                color: dark.$color_text-secondary
            &-demographic
                color: dark.$color_text-primary
        &-divider.MuiDivider-root
            border-color: dark.$color_divider-section
        &-stats
            &-info
                h4
                    color: dark.$color_fill-icon-list
                &-title
                    color: dark.$color_text-secondary
