/* Foundational colors */

//Primitives - gray-light
$color_white-50: #FFFFFF
$color_white-20: rgba(255, 255, 255, 0.2)
$color_white-50-a40: rgba(255, 255, 255, 0.4)
$color_white-50-a50: rgba(255, 255, 255, 0.50)
$color_white-50-a70: rgba(255, 255, 255, 0.7)
$color_gray-100: #FAFBFD
$color_gray-150: #EBE9E9
$color_gray-200: #DFE1E5
$color_gray-250: #EBEBEB
$color_gray-ultra-light-20: rgba(217, 217, 217, 0.2)
$color_gray_ultra-light-90: rgba(217, 217, 217, 0.9)
$color_gray-ultra-light-10: rgba(38, 26, 26, 0.1)
$color_gray-200-a50: rgba(223, 225, 229, 0.5)
$color_gray-300: #C5CACE
$color_gray-300-a60: rgba(197, 202, 206, 0.6)
$color_gray-350: #B0B1B5
$color_gray-350-a16: rgba(176, 177, 181, 0.16)   
$color_gray-350-a70: rgba(176, 177, 181, 0.7)
$color_gray-350-a60:rgba(176, 177, 181, 0.6)
$color_gray-400: #9AA2AB
$color_gray-500: #969BA4
$color_gray-550: #B8B8B8
$color_gray-600: #8A8C95
$color_gray-600-a70: rgba(138, 140, 149, 0.7)
$color_gray-650: #666975
$color_gray-700: #555457
$color_gray-750: rgb(66, 70, 80)
$color_gray-800: #333333
$color_gray-900: #191919
$color_gray-900-a25: rgba(25, 25, 25, 0.25)
$color_gray-900-a75: rgba(25, 25, 25, 0.75)
$color_gray-920-a0: rgba(50, 53, 69, 0.00)
$color_gray-920-a30: rgba(59, 62, 80, 0.3)
$color_gray-950: #A8A8A8

//Primitives - gray-dark
$color_black-a50: rgba(0, 0, 0, 0.50)
$color_black-a80: rgba(0, 0, 0, 0.80)
$color_gray-dark-100: #F9F9F9
$color_gray-dark-100-a40: rgba(249, 249, 249, 0.4)
$color_gray-dark-100-a60: rgba(249, 249, 249, 0.6)
$color_gray-dark-100-a90: rgba(249, 249, 249, 0.9)
$color_gray-dark-600: rgba(77, 81, 102, 1)
$color_gray-dark-600-a50: rgba(77, 81, 102, 0.5)
$color_gray-dark-650: #454D5E
$color_gray-dark-660:#4D5166
$color_gray-dark-660-a54: rgba(77, 81, 102, 0.54)
$color_gray-dark-670: #3B3E50
$color_gray-dark-700: #323545
$color_gray-dark-800: #2A2C3B
$color_gray-dark-900: #161824
$color_gray-dark-900-a25: rgba(22, 24, 36, 0.25)
$color_gray-dark-900-a40: rgba(22, 24, 36, 0.4)
$color_gray-dark-900-a60: rgba(22, 24, 36, 0.6)

//Primitives - brand
$color_blue-pressed: #001CC0
$color_blue-deep: #0025FA
$color_blue-dark: #1866FE
$color_blue-dark-a8: rgba(24, 102, 254, 0.08)
$color_blue-dark-a15: rgba(24, 102, 254, 0.15)
$color_blue-dark-a30: rgba(24, 102, 254, 0.3)
$color_blue-skyblue: #00C0FF
$color_blue-highlight: #485C88
$color_blue-ocean: #19A4FA
$color_blue-ocean-dark: #55BEFF
$color_blue-ocean-dark-a20: rgba(85, 190, 255, 0.2)
$color_blue-cornflower: #ACC7FD
$color_blue-cornflower-a30: rgba(172, 199, 253, 0.3)
$color_blue-teal: #A5E2E5
$color_blue-teal-a20: rgba(165, 226, 229, 0.2)
$color_blue-teal-a40: rgba(165, 226, 229, 0.4)
$color_blue-teal-dark: #A5F5F9
$color_blue-teal-lighter: #E5F9FF
$color_blue-hovered-darkm: #9DDAFF 
$color_blue-disabled: #D3EDFF
$color_blue-white-light: #F3F7FF
$color_blue-white-dark: rgba(172, 199, 253, 0.05)

//Primitives - tables
$color_header-dark: #3B3E50

//Primitives - status
$color_notif-success: #27AA32
$color_notif-success-darkmode: #A4EBB6
$color_notif-warning: #FFDB72
$color_notif-support: #FFC58F
$color_notif-support-deep: #FFA755
$color_notif-support-darkmode: rgba(255, 77, 77, 1)
$color_notif-info: #1866FE
$color_notif-warning-darkmode: #FFEC88
$color_notif-error: rgba(232, 49, 49, 1)
$color_notif-error-light: rgba(232, 49, 49, 0.2)
$color_notif-error-darker: rgba(232, 49, 49, 0.4)
$color_notif-error-darkmode: rgba(255, 77, 77, 1)
$color_notif-failed: #E83131
$color_notif-failed-dark: #FF4D4D
$color_notif-live: #F291E1
$color_notif-live-darkmode: #FFBEF4

//Primitives - supporting
$color_yellow: #FBD975
$color_yellow_predator: #FDB735
$color_orange-medium: #FFC58F
$color_orange-light: #FFD7B2
$color_orange-light-a20: rgba(255, 215, 178, 0.2)
$color_orange-light-a70: rgba(255, 215, 178, 0.7)
$color_pink: #F291E1
$color_pink_potion: #FF4165
$color_pink-medium: #FFBEF4
$color_pink-light: #F2D5ED
$color_pink-light-a20: rgba(242, 213, 237, 0.2)
$color_pink-light-a70: rgba(242, 213, 237, 0.7)
$color_green-dark: #193D0B
$color_green-neon: #B8E55C
$color_green-plant: #4D9953
$color_teal-deep: #556975
$color_green-olive: #829984
$color_green-light: #DAE5C3
$color_green-light-a20: rgba(218, 229, 195, 0.2)
$color_charcoal: #49454F
$color_charcoal-light-a50: rgba(154, 156, 165, 0.5) 
$color_green-light-a70: rgba(218, 229, 195, 0.7)

// Primitives - default avatar color gradients
$color_default-avatar-gradient-1: -webkit-linear-gradient(140deg, #ffb4ea, #ff4165)
$color_default-avatar-gradient-2: -webkit-linear-gradient(140deg, #b4ffb7, #00e25b)
$color_default-avatar-gradient-3: -webkit-linear-gradient(140deg, #66f6ff, #2c7cda)
$color_default-avatar-gradient-4: -webkit-linear-gradient(140deg, #ffeeaf, #ff7656)
$color_default-avatar-gradient-5: -webkit-linear-gradient(140deg, #997dff, #f098ec)
$color_default-avatar-gradient-6: -webkit-linear-gradient(140deg, #80c3f3, #00f6e1)
$color_default-avatar-gradient-7: -webkit-linear-gradient(140deg, #86ffff, #7d43ff)
$color_default-avatar-gradient-8: -webkit-linear-gradient(140deg, #4a75e2, #ff1a45)
    
// Primitives - Component Layout Values
$width_navigation-bar: 64px
$width_public-list-overview: 425px
$width_public-list-overview-expanded: 650px
$width_public-list-right-panel-expanded: 500px

// Primitives - Chart Colors
$color_empty-chart: #DFE1E566
$color_empty-chart-dark: #4D51664D
$color_purple-chart: #A39CF7
$color_blue-chart-dark: #5265F3
$color_blue-chart: #B1C6F8
$color_teal-chart: #D8F0F1
$color_green-chart: #B2E4DE
$color_teal-chart-dark: #9DEAED
$color_pink-chart: #F2D5ED
$color_pink-chart-dark: #FFBEF4

// Primitives - Banner
$color-cyan-light: #DBF3F5
$color-cyan-light-70: rgba(219, 243, 245, 0.7)
$color-cyan-light-50: rgba(219, 243, 245, 0.5)

$color_chart-background-light: #FAFBFD
$color_chart-background-dark: #474D5D
