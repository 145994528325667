@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_modal-campaign-deliverable-preview-v2
    display: flex
    flex-direction: column
    flex: 0 0 33%
    margin-top: $space_2x
    gap: $space_2x
    &-container
        display: flex
        flex-direction: column
        gap: $space_1x
        height: 100%
        background-color: $color_fill-background
        &-head
            align-items: center
            display: flex
            justify-content: space-between
            p
                font-weight: 600
                padding-left: $space_2x
            &-controls
                display: flex
                gap: $space_1x
        &-body
            background-color: $color_surface-secondary
            flex-grow: 1
            padding: $space_2x
            .MuiAvatar-root, video
                height: auto
                width: 100%
            p.caption
                font-size: 0.75rem
                font-weight: 400
                margin: $space_1x 0
                overflow-wrap: anywhere
                white-space: pre-line
            p.caption.compact
                -webkit-box-orient: vertical
                -webkit-line-clamp: 3
                display: -webkit-box
                overflow: hidden
                white-space: normal
            .cp-btn.MuiButton-root.cp-btn_secondary.MuiButton-text
                margin: 0

.dark
    .cp_component_modal-campaign-deliverable-preview-v2
        &-container
            background-color: dark.$color_fill-card
        &-container-body
            background-color: dark.$color_surface-primary
        