@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *
@use "../../style/_primitives" as *

.cp_list-group_management-table
    display: flex
    flex-direction: column
    gap: $space_2x
    height: 100%
    overflow: visible
    padding: 1rem 1.5rem
    width: 100%

    &-action-buttons
        align-self: flex-end
        align-items: center
        display: flex
        gap: $space_2x
        overflow: visible

        .cp_icon-button_component.MuiIconButton-root.small
            border-radius: 5px
            background-color: $color_button-exit
            color: $color_icon-exit
            height: px_to_rem(32px)
            width: px_to_rem(32px)

    &-data-table
        overflow: auto
        width: calc(100% - $space_2x)

    .MuiDataGrid-root
        .MuiDataGrid-pinnedColumns--left, .MuiDataGrid-virtualScrollerRenderZone
            .MuiDataGrid-cell
                padding-left: $space_4x

.dark
    .cp_list-group_management-table
        &-action-buttons
            .cp_icon-button_component.MuiIconButton-root.small
                background-color: dark.$color_button-exit
                color: dark.$color_icon-exit
