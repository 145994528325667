@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_page_public-list-page
  background-image: url("../../asset/img/background_start_pages_light.svg")
  background-size: 1516px 972px
  background-position: 60% 35%

  &-flex
    backdrop-filter: blur(3rem)
    height: 400px

    &_header
      backdrop-filter: blur(4rem)
      background-color: $color_fill-card
      border: solid 1px #f1f1f1
      opacity: 80%
      padding: $space_1x

      &-logo
        background-color: $color_fill-card
        background-image: url("../../asset/img/logo_radius_text_light.svg")
        background-size: 84px 28px
        height: px-to-rem(28px)
        width: px-to-rem(84px)

    &_overview-loading,
    &_overview-error
      background: $color_fill-card
      border-radius: px-to-rem(10px)
      border: 1px solid $color_border-colorcard
      display: flex
      flex-direction: row
      justify-content: center
      margin: $space_2x
      padding: $space_1x

    &_overview
      background: $color_fill-card
      border-radius: px-to-rem(10px)
      border: 1px solid $color_border-colorcard
      display: flex
      flex-direction: row
      margin: $space_2x
      padding: $space_1x

      &_component
        &-info
          &-flex
            backdrop-filter: blur(3rem)
            display: flex
            flex-direction: row
            height: 100%
            width: 100%

          &-avatar-container
            margin: 5px $space_2x 0 $space_2x
            .cp-network-icon-svg
              scale: px-to-rem(42px)
        

          &-name-epsilon-container
            margin: auto auto auto 0
            display: flex
            flex-direction: column  
            .cp_page_public-list-page-flex_overview_component-info-name-epsilon-container-tool-tip.MuiTooltip-tooltip
                width: 12rem
            
            &-notes
              color: $color_text_notes
              font-weight: $font-weight-normal
              font-size: px-to-rem(10px)
              letter-spacing: 0%
              margin-top: 0.25rem
              
          &-epsilon-container
            background-image: url("../../asset/img/epsilon.svg")
            background-repeat: no-repeat
            background-size: contain
            cursor: pointer
            display: block
            float: left
            min-height: px-to-rem(13px)
            min-width: px-to-rem(46px)
            width: 50px
            
          &-name-container
            display: flex
            flex-direction: row
            margin: auto auto auto 0

            &-icon-container
              margin-left: $space_1x
              margin-right: $space_2x
              &-icon
                color: $color_text-invert
                fill: $color_progress-bar-3 !important

        &-right-column
          display: flex
          flex: 4
          gap: 3rem
          justify-content: center
          padding: $space_2x
          padding-left: 3.5rem
          @media screen and (max-width: $md)
              padding-left: $space_2x


        &-controls
          align-items: flex-start
          display: flex
          flex: 1
          gap: $space_1x
          justify-content: flex-end
          margin-bottom: auto
          @media screen and (max-width: $md)
              flex-wrap: wrap

          .MuiIconButton-root
            border-radius: 5px
            background-color: $color_button-exit
            color: $color_icon-exit
            height: px-to-rem(34px)
            width: px-to-rem(34px)

        &-metrics       
          align-items: center
          display: flex
          flex: 4
          flex-direction: row
          gap: $space_6x
          justify-content: flex-start
        &-metrics-wrapper
          display: flex
          flex-direction: column
        
        &-metric
          align-content: center
          align-items: center
          display: flex
          flex-direction: row
          gap: $space_1x
          height: 100%
          justify-content: center

          &-value
            @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
            text-transform: uppercase
            .cp-network-icon
              border-radius: none
              height: 0.75rem
              width: 0.75rem
              .cp-network-icon-svg
                height: 0.75rem
                width: 0.75rem
                  
              &_instagram
                background-color: inherit
                .cp-network-icon-svg
                  background-image: url($instagram-icon-text-primary)

              &_facebook
                background-color: inherit
                .cp-network-icon-svg
                  background-image: url($facebook-icon-text-primary)

              &_youtube
                background-color: inherit
                .cp-network-icon-svg
                  background-image: url($youtube-icon-text-primary)

              &_snapchat
                background-color: inherit
                .cp-network-icon-svg
                  background-image: url($snapchat-icon-base)

              &_tiktok
                background-color: inherit
                .cp-network-icon-svg
                  background-image: url($tiktok-icon-text-primary)

    &_description
      background: $color_fill-card
      border-radius: px-to-rem(10px)
      border: 1px solid $color_border-colorcard
      display: flex
      flex-direction: column
      gap: $space_2x
      margin: $space_2x
      padding: 18px 28px 18px 40px

      &-title
        @include textStyles($color_text-secondary, $font-size-sm, $font-weight-bolder)
        line-height: 100%
        text-transform: uppercase

      &-text
        @include textStyles($color_text-secondary, $font-size-sm, $font-weight-normal)
        line-height: 100%

    &_content
      background: $color_fill-card
      border-radius: px-to-rem(10px)
      border: 1px solid $color_border-colorcard
      height: calc( 100vh - 150px )
      margin: 0 $space_2x
      padding: $space_2x

      &-loading,
      &-error
        display: flex
        flex-direction: row
        justify-content: center
        margin-top: $space_4x
        width: 100%

      .cp_component_pl-category-accounts
        display: flex
        flex-direction: row
        gap: $space_2x
        height: calc( 100vh - 250px )

        .cp_component_pl-category-left-panel
          border-radius: $content-border-radius
          border: 1px solid $color_border-colorcard
          display: flex
          flex-direction: column
          gap: $space_2x
          padding: $space_2x
          width: px-to-rem(392px)

          .cp_component_pl-account-card
            border-radius: px-to-rem(10px)
            border: 1px solid $color_border-colorcard
            display: flex
            flex-direction: column
            gap: $space_2x
            padding: $space_2x

            &.pending-approval
              border: 1px solid $color_button-pressed

            &-header
              display: flex
              flex-direction: row
              justify-content: space-between
              padding-bottom: $space_1x

              &:hover
                cursor: pointer

              &-selector
                align-content: center
                .select-account-button
                  border: none

            &-cta
              border-top: 1px solid $color_fill-banner
              display: flex
              flex-direction: row
              padding-top: $space_2x
              gap: $space_3x

              &-approve-button,
              &-feedback-button
                align-content: center
                display: flex
                flex-direction: row
                gap: $space_1x

              &-approve-button
                .approved-button
                  border: none

                  &.approved-state
                    background-color: $color_color-success
                    border-radius: px-to-rem(10px)
                    color: $color_text-invert

                  &.pending-approval-state
                    background-color: $color_button-pressed
                    border-radius: px-to-rem(10px)
                    color: $color_text-invert

                .approved-label
                  @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-bolder)
                  align-content: center
                  text-transform: uppercase
                  

              &-feedback-button
                .feedback-badge
                  .feedback-button
                    border: none
                  .MuiBadge-badge
                    font-size: $font-size-xs
                    height: 16px
                    min-width: 16px
                .feedback-label
                  @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-bolder)
                  align-content: center
                  text-transform: uppercase

        .cp_component_pl-right-panel
          display: flex
          flex-direction: column
          flex: 1
          gap: $space_2x
          
          &-approval-overlay
            align-items: center
            border: 1px solid $color_border-colorcard
            border-radius: $content-border-radius
            display: flex
            height: 100%
            justify-content: center
            width: 100%

            &-content
              align-items: center
              display: flex
              flex-direction: column
              gap: $space_2x
              width: px-to-rem(350px)

              p
                @include textStyles($color_text-primary, $font-size-large, $font-weight-normal)

            &-check-icon
              height: px-to-rem(80px)
              margin-bottom: $space_4x
              width: px-to-rem(80px)

              .checkmark-icon
                fill: $color_color-success
                height: px-to-rem(80px)
                width: px-to-rem(80px)

            &-actions
              display: flex
              flex-direction: column
              gap: $space_2x
              margin-top: $space_8x
              width: 100%


          &-feedback
            border: 1px solid $color_border-colorcard
            border-radius: $content-border-radius
            display: flex
            flex-direction: column
            gap: $space_2x
            padding: $space_3x
            width: 100%

            &-header
              border-bottom: 1px solid $color_border-colorcard
              display: flex
              flex-direction: row
              justify-content: space-between
              padding-bottom: $space_1x

              &-avatar
                display: flex
                flex-direction: column
                gap: $space_3x

                &-title
                  align-items: center
                  display: flex
                  flex-direction: row
                  gap: $space_1x

                  .static-feedback-title
                    @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)

                  .feedback-counter
                    @include textStyles($color_text-primary, px-to-rem(10px), $font-weight-bolder)
                    background-color: $color_fill-numbercounter
                    border: 1px solid $color_border-numbercounter
                    border-radius: px-to-rem(4px)
                    gap: px-to-rem(10px)
                    height: px-to-rem(16px)
                    letter-spacing: 0%
                    padding-left: px-to-rem(4px)
                    width: px-to-rem(16px)

              &-closer
                &-icon
                  fill: $color_text-inactive

            &-content
              align-items: center
              display: flex
              flex-direction: column
              flex: 1
              gap: $space_2x
              overflow: auto

              &-no-feedback
                align-items: center
                display: flex
                flex-direction: column
                margin: auto

                .no-feedback-text
                  @include textStyles($color_text-inactive, $font-size-normal, $font-weight-normal)

              .cp_component_pl-comment-card
                background-color: $color_fill-section-content
                padding: $space_2x
                width: px-to-rem(643px)

                &-header
                  border-bottom: 1px solid $color_border-colorcard
                  padding-bottom: $space_1x

                  &-name
                    @include textStyles($color_text-primary, $font-size-normal, $font-weight-normal)

                  &-company
                    @include textStyles($color_text-follower, $font-size-normal, $font-weight-bolder)
                    
                &-content
                  @include textStyles($color_text-secondary, $font-size-sm, $font-weight-normal)
                  padding: $space_3x 0

                &-footer
                  @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
                  display: flex
                  flex-direction: row
                  justify-content: flex-end

            &-footer
              background-color: $color_fill-footer
              display: flex
              flex-direction: column
              gap: $space_2x
              padding: px-to-rem(18px) px-to-rem(30px) px-to-rem(16px) px-to-rem(30px)

              &-feedback-message
                width: 100%

                .MuiFormControl-root
                  .MuiInputBase-root
                    .MuiInputBase-input
                      height: px-to-rem(100px) !important


              &-submit-button
                width: px-to-rem(227px)

.dark
  .cp_page_public-list-page

    &-flex
      &_header
        background-color: dark.$color_fill-card
        &-logo
          background-color: dark.$color_fill-card
          background-image: url("../../asset/img/logo_radius_text_dark.svg")

      &_overview-loading,
      &_overview-error
        background: dark.$color_fill-card
        border: 1px solid dark.$color_border-colorcard

      &_overview
        background: dark.$color_fill-card
        border: 1px solid dark.$color_border-colorcard

        &_component
          &-info
            &-flex
            &-name-epsilon-container
              &-notes
                color: dark.$color_text_notes
            &-name-container
              &-icon-container
                &-icon
                  color: dark.$color_text-invert
                  fill: dark.$color_progress-bar-3 !important
            &-epsilon-container
              background-image: url("../../asset/img/epsilon_dark.svg")

          &-controls
            .MuiIconButton-root
              background-color: dark.$color_button-exit
              color: dark.$color_icon-exit
          &-metric
            &-value
              @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-bolder)
              .cp-network-icon
                &_instagram
                    background-color: dark.$color_fill-card
                    .cp-network-icon-svg
                        background-image: url($instagram-icon-text-primary-dark)

                &_facebook
                    background-color: dark.$color_fill-card
                    .cp-network-icon-svg
                        background-image: url($facebook-icon-text-primary-dark)

                &_youtube
                    background-color: dark.$color_fill-card
                    .cp-network-icon-svg
                        background-image: url($youtube-icon-text-primary-dark)

                &_snapchat
                    background-color: dark.$color_fill-card
                    .cp-network-icon-svg
                        background-image: url($snapchat-icon-base)

                &_tiktok
                    background-color: dark.$color_fill-card
                    .cp-network-icon-svg
                        background-image: url($tiktok-icon-text-primary-dark)

      &_description
        background: dark.$color_fill-card
        border: 1px solid dark.$color_border-colorcard

        &-title
          @include textStyles(dark.$color_text-secondary, $font-size-sm, $font-weight-bolder)

        &-text
          @include textStyles(dark.$color_text-secondary, $font-size-sm, $font-weight-normal)

      &_content
        background: dark.$color_fill-card
        border: 1px solid dark.$color_border-colorcard

        .cp_component_pl-category-accounts
          .cp_component_pl-category-left-panel
            border: 1px solid dark.$color_border-colorcard

            .cp_component_pl-account-card
              border: 1px solid dark.$color_border-colorcard

              &.pending-approval
                border: 1px solid dark.$color_button-pressed

              &-cta
                border-top: 1px solid dark.$color_fill-banner

                &-approve-button
                  .approved-button
                    &.approved-state
                      background-color: dark.$color_color-success
                      color: dark.$color_text-invert

                    &.pending-approval-state
                      background-color: dark.$color_button-pressed
                      color: dark.$color_text-invert

                  .approved-label
                    @include textStyles(dark.$color_text-secondary, px-to-rem(10px), $font-weight-bolder)

                &-feedback-button
                  .feedback-label
                    @include textStyles(dark.$color_text-secondary, px-to-rem(10px), $font-weight-bolder)

          .cp_component_pl-right-panel
            &-approval-overlay
              &-content
                p
                  @include textStyles(dark.$color_text-primary, $font-size-large, $font-weight-normal)
              &-check-icon
                .checkmark-icon
                  fill: dark.$color_color-success
            &-feedback
              border: 1px solid dark.$color_border-colorcard
              &-header
                border-bottom: 1px solid dark.$color_border-colorcard
                &-avatar
                  &-title
                    .static-feedback-title
                      @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bold)
                    .feedback-counter
                      @include textStyles(dark.$color_text-primary, px-to-rem(10px), $font-weight-bolder)
                      background-color: dark.$color_fill-numbercounter
                      border: 1px solid dark.$color_border-numbercounter
                &-closer
                  &-icon
                    fill: dark.$color_text-inactive
              &-content
                &-no-feedback
                  .no-feedback-text
                    @include textStyles(dark.$color_text-inactive, $font-size-normal, $font-weight-normal)
                .cp_component_pl-comment-card
                  background-color: dark.$color_fill-section-content
                  &-header
                    border-bottom: 1px solid dark.$color_border-colorcard
                    &-name
                      @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-normal)
                    &-company
                      @include textStyles(dark.$color_text-follower, $font-size-normal, $font-weight-bolder)
                  &-content
                    @include textStyles(dark.$color_text-secondary, $font-size-sm, $font-weight-normal)
                  &-footer
                    @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-bolder)
              &-footer
                background-color: dark.$color_fill-footer


