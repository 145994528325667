@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_communications-thread
  overflow: scroll
  .cp_ct
    &-thread-header
      align-items: center
      border-bottom: 1px solid $color_border-section
      display: flex
      height: px-to-rem(60px)
      justify-content: space-between
      padding: $space_1x $space_2x
      width: 100%
      .create-new-deliverable-button
        align-items: center
        display: flex
        gap: $space_1x
        margin: 0
        padding: 0
        width: fit-content

        & .MuiSvgIcon-root
          fill: $color_icon-pill-2
          height: px-to-rem(20px)
          width: px-to-rem(20px)
        p
          color: $color_text-primary
          font-size: px-to-rem(10px)
        &:hover
          cursor: pointer
    
      &_actions
        margin-top: $space_1x

    &-heading
      @include textStyles($color_text-primary, px-to-rem(10px), $font-weight-normal)     
      border-bottom: 1px solid $color_border-section
      padding: $space_2x

    &-subject
      @include textStyles($color_text-primary, $space_2x, $font-weight-bold)
      padding: $space_2x $space_2x $space_1x
      text-transform: capitalize

    &-thread
      border-bottom: 1px solid $color_border-section
      padding: $space_2x

      &.first
        padding-bottom: $space_3x
      
      &.last
        padding-top: $space_3x

      &-message-content-top
        align-items: center
        display: flex
        margin-bottom: $space_1x

      &-handle-name
        @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-bold)
        padding-left: $space_1x

      &-message-address
        @include textStyles($color_text-follower, $font-size-sm, $font-weight-normal)
        padding-left: $space_1x
      
      &-email-status
        margin-left: $space_1x

      &-date
        @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-normal)
        margin-left: auto

      .account-label-pills-style
        .custom-pill-color-contracting
          background-color: $color_fill-pill-2
          border-color: $color_border-pill-2
          margin: px-to-rem(3px)

        .custom-pill-color-garnering-content
          background-color: $color_fill-pill-4
          border-color: $color_border-pill-4
          margin: px-to-rem(3px)

        .custom-pill-color-opted-in
          background-color: $color_fill-pill-3
          border-color: $color_border-pill-3
          margin: px-to-rem(3px)

      &-to,
      &-cc 
        @include textStyles($color_text-follower, $font-size-sm, $font-weight-normal)
        padding-left: px-to-rem(36px)

      &-to
        margin-bottom: $space_3x
        &.has-cc
          margin-bottom: $space_1x

      &-cc
        margin-bottom: $space_3x

      &-body
        @include textStyles($color_text-body, px-to-rem(14px), $font-weight-normal)
        overflow-wrap: break-word // prevents long links to overflow the container
        white-space: normal

        a // Handle links withing the body of the message
          color: $color_button-primary
        
    &-thread-content
      h2.cp_ct-thread-content-heading 
        @include textStyles($color_text-body, px-to-rem(14px), $font-weight-normal)
        margin-bottom: $space_2x

      &-top
        align-items: center
        column-gap: $space_1x
        display: flex
        margin-bottom: $space_2x

        .email-attachment-icon
          width: $space_2x
          path
            fill: $color_icon-pill
        p
          @include textStyles($color_text-body, px-to-rem(10px), $font-weight-bolder)


    &-thread-attachments 
      column-gap: $space_1x
      display: flex

      &.has-snippets
        margin-bottom: $space_1x

      a.cp_ct-thread-attachment
        display: block
        height: px-to-rem(70px)
        width: px-to-rem(70px)

        img
          border-radius: $content-border-radius
          border: 1px solid $color_border-image-user
          height: 100%
          object-fit: cover
          width: 100%

        div.cp_ct-thread-attachment-icon-placeholder
          background-color: $color_fill-loading
          display: flex
          padding: px-to-rem(19px)
          svg.cp_ct-thread-attachment-icon-download
            fill: $color_border-statuscolor
            font-size: px-to-rem(32px)

    &-thread-snippets 

      .cp_ct-thread-snippet
        align-items: center
        background-color: $color_surface-secondary
        border: 1px solid $color_border-image
        display: flex 
        height: auto
        justify-content: center
        padding: $space_3x
        width: 100%

    &-message-replay
      &-button-section
        padding: $space_4x $space_2x
      &-form
        padding: $space_2x
        &-template
          padding-bottom: $space_3x

          p
            @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
            margin-bottom: px-to-rem(5px)
            text-transform: uppercase
          .component_select
            width: 350px

          .top-fields-container,
          .upload-link-container
            align-items: center
            display: flex

          .top-fields-container
            gap: $space_2x

          .upload-link-container
            label
              @include textStyles($color_text-radiobutton, $font-size-sm, $font-weight-normal)

        &-divider
          margin-bottom: $space_2x

        &-configure
          margin-bottom: $space_3x
          &-fields
             > p
              @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
              margin-bottom: px-to-rem(5px)
              text-transform: uppercase    

          &-to_field.cp_component_input,
          .cp_autocomplete_comm-group-email_input.cp_component_input
            margin-bottom: $space_2x
            width: 100%

            div.MuiChip-root
              background-color: $color_fill-pill-1
              border: 1px solid $color_border-pill
              height: $space_3x

            span.MuiChip-label 
              @include textStyles($color_text-primary, $font-size-sm, $font-weight-bold)
              line-height:  $space_3x

            button.cc-labels
              @include textStyles($color_text-inlinebutton, px-to-rem(14px), $font-weight-normal)
              cursor: pointer
              text-transform: uppercase

          .cp_autocomplete_comm-group-email_input.cp_component_input
              div.MuiOutlinedInput-root
                display: flex
                gap: $space_1x
                justify-content: flex-start
                padding: $space_1x

              div.MuiAutocomplete-tag
                margin: 0

              input.MuiInputBase-input
                padding: 0
  
        &-actions
          align-items: center
          column-gap: px-to-rem(8px)
          display: flex
    &-show-more
      position: relative
      button.cp_ct-show-more-text
        @include textStyles($color_text-invert, $font-size-sm, $font-weight-bold)
        background-color: $color_fill-logo
        left: $space_2x
        min-width: $space_3x
        padding: px_to_rem(4px) $space_1x
        position: absolute
        top: px-to-rem(-12px)
        &:hover
          cursor: pointer

.dark
  .cp_ct
    &-thread-header
      border-color: dark.$color_border-section
      .create-new-deliverable-button
        & .MuiSvgIcon-root
          fill: dark.$color_icon-pill-2
        p
          color: dark.$color_text-primary
    &-heading
      @include textStyles($color_text-primary, px-to-rem(10px), $font-weight-normal)
      border-bottom-color: dark.$color_border-section
      color: dark.$color_text-primary     

    &-subject
      color: dark.$color_text-primary

    &-thread
      border-bottom-color: dark.$color_border-section

      &-handle-name
        color: dark.$color_text-primary

      &-message-address
        color: dark.$color_text-follower

      &-date
        color: dark.$color_text-secondary

      .account-label-pills-style
        .custom-pill-color-contracting
          background-color: dark.$color_fill-pill-2
          border-color: dark.$color_border-pill-2

        .custom-pill-color-garnering-content
          background-color: dark.$color_fill-pill-4
          border-color: dark.$color_border-pill-4

        .custom-pill-color-opted-in
          background-color: dark.$color_fill-pill-3
          border-color: dark.$color_border-pill-3

      &-to,
      &-cc 
        color: dark.$color_text-follower

      &-body
        color: dark.$color_text-body

        a // Handle links withing the body of the message
          color: dark.$color_button-primary
        
              
    &-thread-content
      h2.cp_ct-thread-content-heading 
        color: dark.$color_text-body

      &-top

        .email-attachment-icon
          path
            fill: dark.$color_icon-pill
        p
          color: dark.$color_text-body

    &-thread-attachments 

      a.cp_ct-thread-attachment

        img
          border-color: dark.$color_border-image-user

        div.cp_ct-thread-attachment-icon-placeholder
          background-color: dark.$color_fill-loading
          svg.cp_ct-thread-attachment-icon-download
            fill: dark.$color_border-statuscolor

    &-thread-snippets 

      .cp_ct-thread-snippet
        background-color: dark.$color_surface-secondary
        border-color: dark.$color_border-image

    &-message-replay
      &-form
        &-template
          p
            color: dark.$color_text-primary
          .upload-link-container
            label
              color: dark.$color_text-radiobutton

        &-configure
          &-fields
            > p
              color: dark.$color_text-primary

            &-to_field.cp_component_input,
            .cp_autocomplete_comm-group-email_input.cp_component_input

              div.MuiChip-root
                background-color: dark.$color_fill-pill-1
                border-color: dark.$color_border-pill

              span.MuiChip-label 
                color: dark.$color_text-primary

              button.cc-labels
                color: dark.$color_text-inlinebutton

    &-show-more
      button.cp_ct-show-more-text
        background-color: dark.$color_fill-logo
        color: dark.$color_text-invert
